<div class="settings-menu vertical-tab">
  <div class="nav-item flex-column nav-pills">
    <ng-template ngxPermissionsExcept="Admin">
      <a class="nav-link" routerLink="/setting/account" [class.active]="componentType[1].name === 'Account'">
        Account
      </a>
    </ng-template>

    <ng-template ngxPermissionsOnly="Publisher">
      <a class="nav-link" routerLink="/setting/ad-space" [class.active]="componentType[1].name === 'AdSpace'">
        AdSpace
      </a>
    </ng-template>

    <ng-template ngxPermissionsExcept="Admin">
      <a class="nav-link" routerLink="/setting/finance-settings" [class.active]="componentType[1].name === 'Finance Settings'">
        Finance Settings
      </a>
    </ng-template>

    <a class="nav-link" routerLink="/setting/user-management" [class.active]="componentType[1].name === 'User Management'">
      User Management
    </a>

    <a
      class="nav-link custom-bottom-line"
      routerLink="/setting/notification-settings"
      [class.active]="componentType[1].name === 'Notification Settings'"
    >
      Notification Settings
    </a>

    <a class="nav-link" routerLink="/setting/password-settings" [class.active]="componentType[1].name === 'Password Settings'">
      Password Settings
    </a>

    <a
      *ngIf="isCurrentAccountAdmin"
      class="nav-link"
      routerLink="/setting/admin-settings"
      [class.active]="componentType[1].name === 'Admin Settings'"
    >
      Admin Settings
    </a>

    <a
      *ngIf="isCurrentAccountAdmin"
      class="nav-link"
      routerLink="/setting/account-uploads-settings"
      [class.active]="componentType[1].name === 'Account Uploads'"
    >
      Account Uploads
    </a>
  </div>
</div>
