<div class="tab-content">
  <div class="tab-pane fade show active">
    <form [formGroup]="accountSettingForm" (ngSubmit)="onSubmit()">
      <div class="card">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-lg-4">
              <!-- Account Info -->
              <div *ngIf="accountInfo">
                <div formGroupName="accountInfo">
                  <h3 class="title">Account Info</h3>
                  <div class="form-group">
                    <label>Account Type*</label>

                    <ng-select
                      bindValue="id"
                      bindLabel="name"
                      formControlName="accountType"
                      [items]="companyTypeFormOptions"
                      (change)="checkAccountType($event)"
                      placeholder="Select"
                      [class.is-invalid]="submitted && accountInfoGroupControl('accountType').errors"
                      class="expanding-dropdown-panel"
                      appendTo="body"
                    >
                    </ng-select>

                    <div *ngIf="submitted && accountInfoGroupControl('accountType').errors" class="invalid-feedback">
                      <div *ngIf="accountInfoGroupControl('accountType').errors?.required">
                        Account Type required
                      </div>
                    </div>
                  </div>
                  <div *ngIf="isAccountCommercial === true">
                    <!-- Commercial -->
                    <div class="form-group">
                      <label>Company Form*</label>

                      <ng-select
                        bindValue="id"
                        bindLabel="name"
                        formControlName="companyForm"
                        [items]="companyFormTypeOptions"
                        placeholder="Select"
                        [class.is-invalid]="submitted && accountInfoGroupControl('companyForm').errors"
                        class="expanding-dropdown-panel"
                        appendTo="body"
                      >
                      </ng-select>

                      <div *ngIf="submitted && accountInfoGroupControl('companyForm').errors" class="invalid-feedback">
                        <div *ngIf="accountInfoGroupControl('companyForm').errors?.required">
                          Company form type required
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Company Name*</label>

                      <input
                        formControlName="companyName"
                        type="text"
                        class="form-control"
                        placeholder="Company Name"
                        [class.is-invalid]="submitted && accountInfoGroupControl('companyName').errors"
                      />

                      <div *ngIf="submitted && accountInfoGroupControl('companyName').errors" class="invalid-feedback">
                        <div *ngIf="accountInfoGroupControl('companyName').errors?.required">
                          Account Type required
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Company ID</label>

                      <input formControlName="companyId" type="text" class="form-control" placeholder="Company Id" />
                    </div>
                  </div>

                  <div *ngIf="isAccountCommercial === false">
                    <!-- Private -->
                    <div class="form-group">
                      <label>First Name*</label>
                      <input
                        formControlName="firstName"
                        type="text"
                        class="form-control"
                        placeholder="First Name"
                        [class.is-invalid]="submitted && accountInfoGroupControl('firstName').errors"
                      />
                      <div *ngIf="submitted && accountInfoGroupControl('firstName').errors" class="invalid-feedback">
                        <div *ngIf="accountInfoGroupControl('firstName').errors?.required">
                          First Name required
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Last Name*</label>
                      <input
                        formControlName="lastName"
                        type="text"
                        class="form-control"
                        placeholder="Last Name"
                        [class.is-invalid]="submitted && accountInfoGroupControl('lastName').errors"
                      />
                      <div *ngIf="submitted && accountInfoGroupControl('lastName').errors" class="invalid-feedback">
                        <div *ngIf="accountInfoGroupControl('lastName').errors?.required">
                          Last Name required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <!-- Address -->
              <div formGroupName="address">
                <h3 class="title">Address</h3>
                <div class="form-group">
                  <label>Street and Number</label>
                  <input
                    formControlName="streetNameAndNumber"
                    type="text"
                    class="form-control"
                    placeholder="Street and Number"
                    [class.is-invalid]="submitted && addressGroupControl('streetNameAndNumber').errors"
                  />

                  <div *ngIf="submitted && addressGroupControl('streetNameAndNumber').errors" class="invalid-feedback">
                    <div *ngIf="addressGroupControl('streetNameAndNumber').errors?.required">
                      Street name and number is required
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Building Name and Number</label>
                  <input
                    formControlName="buildingNameAndNumber"
                    type="text"
                    class="form-control"
                    placeholder="Building Name and Number"
                  />
                </div>

                <div class="form-group">
                  <label>ZIP Code*</label>
                  <input
                    formControlName="zipCode"
                    type="text"
                    class="form-control"
                    placeholder="ZIP Code"
                    [class.is-invalid]="submitted && addressGroupControl('zipCode').errors"
                  />
                  <div *ngIf="submitted && addressGroupControl('zipCode').errors" class="invalid-feedback">
                    <div *ngIf="addressGroupControl('zipCode').errors?.required">
                      ZIP Code is required
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>City*</label>
                  <input
                    formControlName="city"
                    type="text"
                    class="form-control"
                    placeholder="City"
                    [class.is-invalid]="submitted && addressGroupControl('city').errors"
                  />
                  <div *ngIf="submitted && addressGroupControl('city').errors" class="invalid-feedback">
                    <div *ngIf="addressGroupControl('city').errors?.required">City is required</div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Country*</label>
                  <ng-select
                    formControlName="country"
                    [items]="countryCodeOptions"
                    bindLabel="name"
                    bindValue="code"
                    placeholder="Select"
                    [class.is-invalid]="submitted && addressGroupControl('country').errors"
                    class="expanding-dropdown-panel"
                    appendTo="body"
                  >
                  </ng-select>
                  <div *ngIf="submitted && addressGroupControl('country').errors" class="invalid-feedback">
                    <div *ngIf="addressGroupControl('country').errors?.required">Country is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <!-- Contact -->
              <div formGroupName="contact">
                <h3 class="title">Contact</h3>
                <div class="form-group">
                  <label>Email Address*</label>
                  <input
                    formControlName="emailAddress"
                    type="text"
                    class="form-control"
                    placeholder="Email Address"
                    [class.is-invalid]="submitted && contactGroupControl('emailAddress').errors"
                  />
                  <div *ngIf="submitted && contactGroupControl('emailAddress').errors" class="invalid-feedback">
                    <div *ngIf="contactGroupControl('emailAddress').errors?.required">Email required</div>
                    <div *ngIf="contactGroupControl('emailAddress').errors?.email">Invalid email</div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Website URL*</label>
                  <input
                    formControlName="websiteUrl"
                    type="text"
                    class="form-control"
                    placeholder="Website URL"
                    [class.is-invalid]="submitted && contactGroupControl('websiteUrl').errors"
                  />
                  <div *ngIf="submitted && contactGroupControl('websiteUrl').errors" class="invalid-feedback">
                    <div *ngIf="contactGroupControl('websiteUrl').errors?.required">
                      Website URL required
                    </div>
                    <div *ngIf="contactGroupControl('websiteUrl').errors?.pattern">
                      Invalid URL (should start with http:// or https://)
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Phone Number*</label>
                  <div class="input-group">
                    <ng-select
                      class="country-dial-code expanding-dropdown-panel"
                      [items]="countryDialCodeOptions"
                      formControlName="countryCode"
                      bindLabel="name"
                      bindValue="dialCode"
                      appendTo="body"
                    >
                      <ng-template ng-label-tmp let-item="item">
                        <span>{{ item.name }} ({{ item.dialCode }})</span>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span>{{ item.name }} ({{ item.dialCode }})</span>
                      </ng-template>
                    </ng-select>
                    <input
                      formControlName="phoneNumber"
                      type="text"
                      class="form-control"
                      value="{{ selectedCountryCode }}"
                      [class.is-invalid]="submitted && contactGroupControl('phoneNumber').errors"
                    />
                    <div *ngIf="submitted && contactGroupControl('phoneNumber').errors" class="invalid-feedback">
                      <div *ngIf="contactGroupControl('phoneNumber').errors?.required">
                        Phone number required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!readOnly" class="card-footer text-right border-0 px-0">
          <button type="submit" class="btn btn-primary">
            Save
          </button>
        </div>
      </div>
      <input type="hidden" formControlName="accountId" />
    </form>
  </div>
</div>
