<div class="content" *ngIf="contentVisible">
  <header class="page-header">
    <div class="d-flex align-items-center">
      <div class="mr-auto">
        <h1>Settings</h1>
      </div>
    </div>
  </header>
  <section class="page-content container-fluid">
    <div class="card settings-card">
      <div class="row no-gutters">
        <div class="col-lg-3 custom-border">
          <app-setting-sidebar></app-setting-sidebar>
        </div>
        <div class="col-lg-9">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </section>
</div>
