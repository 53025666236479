<div class="tab-content">
  <div class="tab-pane fade show active">
    <div class="card">
      <div *ngIf="!readOnly" class="card-header d-flex justify-content-end">
        <button type="button" class="btn btn-primary" (click)="openModal()">
          Add Channel
        </button>
      </div>

      <div class="card-body p-0 overflow-x">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Name</th>
                <th scope="col">Website URL</th>
                <th scope="col">Traffic Channel</th>
                <th scope="col" *ngIf="!readOnly">
                  <span class="v-none"></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let addSpace of addSpaceList">
                <th scope="row">
                  <img *ngIf="addSpace.status === 'Pending'" src="assets/icons/pending.svg" />
                  <img *ngIf="addSpace.status === 'Active'" src="assets/icons/accepted.svg" />
                  <img *ngIf="addSpace.status === 'Inactive'" src="assets/icons/rejected.svg" />
                </th>
                <td>{{ addSpace.name }}</td>
                <td>{{ addSpace.url }}</td>
                <td>
                  <ng-select
                    [items]="trafficDropdownList"
                    [multiple]="true"
                    bindLabel="name"
                    bindValue="id"
                    [ngModel]="addSpace.traffic"
                    readonly="true"
                    class="expanding-dropdown-panel"
                    appendTo="body"
                  >
                  </ng-select>
                </td>
                <td *ngIf="!readOnly">
                  <a (click)="openModal(addSpace.id)">
                    <img class="action-icon" src="assets/icons/pen.svg" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template>
  <app-ad-space-settings-model></app-ad-space-settings-model>
</ng-template>
